<template>
  <div>
    <!--begin::Row-->
    <!-- <div class="row">
            <div class="col-lg-12"> -->
    <div class="card card-custom card-stretch gutter-b">
      <!--begin::Header-->
      <div class="card-header border-0">
        <h3 class="card-title font-weight-bolder text-dark">Panduan</h3>
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <div class="card-body pt-0">
        <!--begin::Item-->
        <div class="mb-12">
          <!--begin::Content-->
          <button
            type="button"
            class="btn btn-primary mb-2"
            size="sm"
            style="background-color: #030a8c; border-color: #030a8c"
            v-b-modal.modal-tambah
          >
            <i class="flaticon2-plus"></i>Tambah
          </button>
          <div>
            <div style="overflow-x: auto; overflow-y: hidden">
              <table
                id="example"
                class="table table-striped table-bordered"
                style="width: 100%"
              >
                <thead>
                  <tr>
                    <th style="width: 5%">No</th>
                    <th style="width: 15%">File/Video</th>
                    <th>Nama</th>
                    <th>Aksi</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(row, index) in kegiatan" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>
                      <span class="symbol-label">
                        <a
                          :href="row.file_url"
                          target="_blank"
                          v-if="
                            row.tipe == 'panduan_apps' ||
                            row.tipe == 'panduan_instrumen'
                          "
                        >
                          <i
                            class="fas fa-solid fa-file-pdf"
                            style="color: red"
                          ></i>
                        </a>
                        <a :href="row.url" target="_blank" v-else>
                          <i
                            class="fas fa-solid fa-video ml-2"
                            style="color: red"
                          ></i>
                        </a>
                      </span>
                    </td>
                    <td>{{ row.nama }}</td>
                    <td>
                      <div>
                        <b-button
                          data-toggle="modal"
                          data-target="#editPanduan"
                          variant="success"
                          size="sm"
                          style="backgorund-color: #66a626"
                          @click="getperfaq(row.id)"
                          class="m-1"
                        >
                          <i class="flaticon-edit"></i>Ubah
                        </b-button>
                        <b-button
                          @click="deleteData(row.id)"
                          size="sm"
                          variant="danger"
                          ><i class="flaticon2-trash"></i> Hapus</b-button
                        >
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!--end::Content-->
        </div>
        <!--end::Item-->
      </div>
      <!--end: Card Body-->
    </div>

    <!-- Tambah -->
    <b-modal
      v-model="show"
      id="modal-tambah"
      ref="modal"
      size="lg"
      title="Tambah Panduan"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form ref="form" @submit.stop.prevent="tambahPanduan">
        <div class="form-group row">
          <label class="col-lg-4 col-form-label">Title</label>
          <div class="col-lg-8">
            <b-form-input v-model="form.nama" class="form-control" />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4">Type</label>
          <div class="col-8">
            <b-form-select
              v-model="form.tipe"
              :options="type"
              @change="
                () => {
                  form.url = null;
                  form.file = null;
                }
              "
            ></b-form-select>
          </div>
        </div>
        <div class="form-group row" v-if="form.tipe == 'video'">
          <label class="col-lg-4 col-form-label">Url Video</label>
          <div class="col-lg-8">
            <b-form-input v-model="form.url" class="form-control" />
          </div>
        </div>
        <div
          class="form-group row"
          v-if="form.tipe == 'panduan_apps' || form.tipe == 'panduan_instrumen'"
        >
          <label class="col-4">File</label>
          <div class="col-8">
            <b-form-file
              accept="application/pdf"
              v-model="form.file"
              type="file"
              ref="file"
            ></b-form-file>
          </div>
        </div>
      </form>
      <template #modal-footer>
        <div class="w-100">
          <!-- <p class="float-left">Modal Footer Content</p> -->
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            style="
              background-color: #030a8c;
              border-color: #030a8c;
              color: white;
            "
            @click="tambahPanduan"
          >
            Kirim
          </b-button>
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            style="margin-right: 10px"
            @click="show = false"
          >
            Tutup
          </b-button>
        </div>
      </template>
    </b-modal>

    <!-- modal edit kegiatan -->
    <div
      class="modal fade"
      id="editPanduan"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLongTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">Ubah Data</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-lg-4 col-form-label">Title</label>
              <div class="col-lg-8">
                <b-form-input v-model="form.nama" class="form-control" />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-4">Type</label>
              <div class="col-8">
                <b-form-select
                  v-model="form.tipe"
                  :options="type"
                  @change="
                    () => {
                      form.url = null;
                      form.file = null;
                    }
                  "
                ></b-form-select>
              </div>
            </div>
            <div class="form-group row" v-if="form.tipe == 'video'">
              <label class="col-lg-4 col-form-label">Url Video</label>
              <div class="col-lg-8">
                <b-form-input v-model="form.url" class="form-control" />
              </div>
            </div>
            <div
              class="form-group row"
              v-if="
                form.tipe == 'panduan_apps' || form.tipe == 'panduan_instrumen'
              "
            >
              <label class="col-4">File</label>
              <div class="col-8">
                <b-form-file
                  accept="application/pdf"
                  v-model="form.file"
                  type="file"
                  ref="file"
                ></b-form-file>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Tutup
            </button>
            <button
              type="button"
              @click="editPanduan(form.id)"
              style="
                background-color: #030a8c;
                border-color: #030a8c;
                color: white;
              "
              data-dismiss="modal"
              class="btn btn-primary"
            >
              Simpan
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- </div>
      </div> -->
</template>
    <style>
div.messages {
  /* background-color: lightblue; */
  /* width: 110px; */
  height: 500px;
  overflow: auto;
}
</style>
    <script>
import Vue from "vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import axios from "axios";
import JsonExcel from "vue-json-excel";
import { VueEditor } from "vue2-editor";

Vue.component("downloadExcel", JsonExcel);
export default {
  name: "dashboard",
  components: { VueEditor },
  data() {
    return {
      url: localStorage.getItem("baseapi"),
      token: localStorage.getItem("id_token"),
      show: false,
      test: "hai",
      kegiatan: [],
      search: "",
      gambar: "",
      pertanyaan: "",
      caption: "",
      jawabantipe: null,
      selectedtype: true,
      selectedTahun: null,
      type: [
        { value: "video", text: "Video" },
        { value: "panduan_apps", text: "Panduan Aplikasi" },
        { value: "panduan_instrumen", text: "Panduan Instrumen" },
      ],
      tahun: [],
      tipe: [
        { value: "aplikasi", text: "Aplikasi" },
        { value: "instrumen", text: "Instrumen" },
        { value: "informasi_pm", text: "Informasi PM" },
        { value: "waktu", text: "Waktu" },
      ],
      getdetail: [],
      form: {
        nama: null,
        tipe: null,
        url: null,
        file: null,
      },
      json_fields: {
        Agenda: "agenda",
        Tahun: "tahun",
      },
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Panduan" }]);
    this.getdata();
    this.rangeTahun();
  },
  methods: {
    rangeTahun() {
      //this.selectedTahunKode = current;
      var currentYear = new Date().getFullYear() + 5;

      console.log(currentYear);
      var startYear = new Date().getFullYear() - 5;
      while (startYear <= currentYear) {
        this.tahun.push(startYear++);
      }
    },
    get_url_extension(url) {
      return url.split(/[#?]/)[0].split(".").pop().trim();
    },
    isValidUrl(urlString) {
      var urlPattern = new RegExp(
        "^(https?:\\/\\/)?" + // validate protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); // validate fragment locator
      return !!urlPattern.test(urlString);
    },
    getdata() {
      $("#example").DataTable().destroy();
      this.kegiatan = [];
      this.loaddata();
    },
    loaddata() {
      axios
        .get(this.url + "/panduan", {
          headers: {
            xth: this.token,
          },
        })
        .then((response) => {
          this.kegiatan = response.data.data;
          this.initDatatable();
        })
        .catch((error) => {
          console.log(error);
          return error;
          // this.loaddata()
        });
    },
    initDatatable() {
      setTimeout(() => {
        var t = $("#example").DataTable({
          pagingType: "full_numbers",
          language: {
            info: "Menampilkan _START_ sampai _END_ dari _TOTAL_ data",
            paginate: {
              first: "Awal",
              last: "Akhir",
              next: "Selanjutnya",
              previous: "Sebelumnya",
            },
          },
          order: [[0, "desc"]],
          responsive: true,
          destroy: true,
          retrieve: true,
          autoFill: true,
          colReorder: true,
        });
        t.on("order.dt search.dt", function () {
          t.column(0, { search: "applied", order: "applied" })
            .nodes()
            .each(function (cell, i) {
              cell.innerHTML = i + 1;
            });
        }).draw();
      }, 300);
    },
    resetModal() {
      this.agenda = "";
      this.status_sistem = "Buka";
      this.tanggal_mulai = "";
      this.tanggal_selesai = "";
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.tambahPanduan();
    },
    tambahPanduan() {
      let formData = new FormData();
      formData.append("file_url", this.form.file);
      formData.append("nama", this.form.nama);

      if (this.form.url == null || this.form.url == "") {
      } else {
        formData.append(
          "url",
          "https://www.youtube.com/embed/" + this.form.url
        );
      }

      formData.append("tipe", this.form.tipe);
      axios
        .post(this.url + "/panduan", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            xth: this.token,
          },
        })
        .then((res) => {
          this.$bvToast.toast("Berhasil Disimpan", {
            title: `Success `,
            variant: `success`,
            solid: true,
          });
          console.log(res);
          this.getdata();
          // localStorage.setItem('idprofil', JSON.stringify(res.data.id))
          return res;
        })
        .catch((err) => {
          console.log(err);
          this.$bvToast.toast("Gagal Disimpan", {
            title: `Failed `,
            variant: `danger`,
            solid: true,
          });
          return err;
        });

      this.$nextTick(() => {
        this.$bvModal.hide("modal-tambah");
      });
    },
    getperfaq(id) {
      axios
        .get(this.url + `/panduan?id=${id}`, {
          headers: {
            xth: this.token,
          },
        })
        .then((response) => {
          // axios.get(`http://penkin-penilaian.mysurvey.id/sdm/?filter=idpm,=,`+this.idpm+`;bagian,=,Kepala Dinas`).then(response => {
          this.form = response.data.data;
          console.log(response.data.data);
          console.log("getdetail");
        });
    },
    handleEdit(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.editsdm();
    },
    editPanduan(id) {
      let formData = new FormData();
      formData.append("file_url", this.form.file);
      formData.append("nama", this.form.nama);

      if (this.form.url == null || this.form.url == "") {
      } else {
        formData.append(
          "url",
          "https://www.youtube.com/embed/" + this.form.url
        );
      }

      formData.append("tipe", this.form.tipe);
      axios
      axios
        .post(this.url + `/panduan/${id}`, formData, {
          headers: {
            xth: this.token,
          },
        })
        .then((res) => {
          this.$bvToast.toast("Berhasil Diedit", {
            title: `Success `,
            variant: `success`,
            solid: true,
          });
          console.log(res);
          this.getdata();
          return res;
        })
        .catch((err) => {
          console.log(err);
          this.$bvToast.toast("Gagal Diedit", {
            title: `Failed `,
            variant: `danger`,
            solid: true,
          });
          return err;
        });
      // Push the name to submitted names
      // this.submittedNames.push(this.name)
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("modal-prevent-closing");
      });
    },
    deleteData(id) {
      axios
        .delete(this.url + `/panduan/${id}`, {
          headers: {
            "Content-type": "application/json",
            xth: this.token,
          },
        })
        .then((res) => {
          this.$bvToast.toast("Berhasil Dihapus", {
            title: `Success `,
            variant: `success`,
            solid: true,
          });
          console.log(res);
          this.getdata();
          return res;
        })
        .catch((err) => {
          console.log(err);
          this.$bvToast.toast("Gagal Dihapus", {
            title: `Failed `,
            variant: `danger`,
            solid: true,
          });
          return err;
        });
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    },
  },
  created() {},
};
</script>
    